// Import the functions you need from the SDKs you need

import {initializeApp} from "firebase/app";

import {getAnalytics} from "firebase/analytics";
import {getFunctions} from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {

    apiKey: "AIzaSyDsOLmoE28ZI6WHYK0nc1hUm13HMSmUXYg",

    authDomain: "siond.com",

    databaseURL: "https://siond.com",

    projectId: "siond-com",

    storageBucket: "siond-com.appspot.com",

    messagingSenderId: "79131993246",

    appId: "1:79131993246:web:1739a01e9c053c2eaf078f",

    measurementId: "G-VNJ326DMTN"

};


// Initialize Firebase
// 最近のFirebaseはAPI取得方法が変わった…。
// functionsはgetFunctions()で取得する。

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);
const functions = getFunctions(app);

export {functions};
