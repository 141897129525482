<script lang="ts">
// import Vue from 'vue';
// import Options from 'vue-class-component';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class Main extends Vue {

}
</script>

<template>
  <p>Studio SION</p>
  <p>Under construction.</p>
</template>

<style scoped lang="scss">

</style>