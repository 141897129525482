<template>
    <div class="cf-wrapper">
        <v-card>
            <v-container>
                <h2>お問い合わせ</h2>
                <v-form ref="form" v-model="contactFormValidation.valid">
                    <v-text-field
                            v-model="contactForm.name"
                            :rules="contactFormValidation.nameRules"
                            label="お名前"
                            required
                    ></v-text-field>
                    <v-text-field
                            v-model="contactForm.email"
                            :rules="contactFormValidation.emailRules"
                            label="メールアドレス"
                            required
                    ></v-text-field>
                    <v-textarea
                            v-model="contactForm.contents"
                            :rules="contactFormValidation.contentsRules"
                            label="内容"
                            required
                    ></v-textarea>
                    <v-container fluid>
                        <v-checkbox
                                readonly
                                v-model="contactForm.check"
                                :rules="contactFormValidation.checkRules"
                                label="check"
                                required
                                style="display: none"
                        ></v-checkbox>
                        <v-row align="center" justify="center">
                            <v-icon dark color="green" :style="contactForm.check_style">mdi-check</v-icon>
                            <v-btn
                                    outlined color="info"
                                    class="ma-2 white--text"
                                    @click="rc_loader = 'contactForm.loading_rc'"
                                    :loading="contactForm.loading_rc"
                                    :disabled="contactForm.loading_rc || contactForm.rc_success"
                            >
                                私はロボットではありません
                            </v-btn>
                        </v-row>
                        <p class="recaptcha_policy">This site is protected by reCAPTCHA and the Google<a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.</p>
                    </v-container>
                    <v-btn
                            :loading="contactForm.loading"
                            :disabled="!contactFormValidation.valid"
                            @click="sendMail()"
                            block
                            large
                            color="info"
                            class="mt-4 font-weight-bold"
                    >送信
                    </v-btn>
                </v-form>
            </v-container>
        </v-card>
        <v-snackbar
                v-model="snackBar.show"
                :color="snackBar.color"
                bottom
                right
                :timeout="6000"
                class="font-weight-bold"
        >
            {{snackBar.message}}
        </v-snackbar>
    </div>
</template>

<script>
    import { functions } from '@/plugins/firebase'
    import { load } from "recaptcha-v3";
    // httpsCallableはfunctions.httpsCallable()で使えなくなった。
    import { httpsCallable } from "firebase/functions";

    export default {
        data: () => ({
            contactForm: {
                name: '',
                contents: '',
                email: '',
                check: '',
                loading: false,
                loading_rc: false,
                rc_success: false,
                check_style: "display: none",
            },
            rc_loader: null,
            token: "",
            result: {},
            contactFormValidation: {
                valid: false,
                nameRules: [v => !!v || '名前は必須項目です'],
                emailRules: [v => !!v || 'メールアドレスは必須項目です',
                    v => /.+@.+\..+/.test(v) || 'メールアドレスが正しくありません',],
                contentsRules: [v => !!v || '内容は必須項目です'],
                checkRules: [v => !!v || 'checkは必須項目です'],
            },
            snackBar: {
                show: false,
                color: '',
                message: ''
            }
        }),
        watch: {
            rc_loader() {
                this.contactForm.loading_rc = !this.contactForm.loading_rc;
                if (this.contactForm.loading_rc) this.check_rc();
            }
        },
        methods: {
            sendMail: async function () {
                if (this.$refs.form.validate()) {
                    this.contactForm.loading = true;

                  // NOTE: functionsをデプロイしておく必要がある
                  //  URLはコンパネ（Google Cloud＞Cloud Functions＞Functions）のトリガーにある
                  fetch("https://us-central1-siond-com.cloudfunctions.net/sendNodeMail", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(this.contactForm),
                  }).then((res) => {
                    if (res.status === 200) {
                      this.formReset();
                      this.contactForm.check = "";
                      this.contactForm.rc_success = false;
                      this.contactForm.check_style = "display: none";
                      this.showSnackBar(
                          'success',
                          'お問い合わせありがとうございます。送信完了しました'
                      );
                      this.contactForm.loading = false;
                    } else {
                      this.showSnackBar(
                          'error',
                          '送信に失敗しました。時間をおいて再度お試しください'
                      );
                      this.contactForm.loading = false;
                    }
                  });
                }
            },
            showSnackBar: function (color, message) {
                this.snackBar.message = message
                this.snackBar.color = color
                this.snackBar.show = true
            },
            formReset: function () {
                this.$refs.form.reset()
            },
            check_rc: async function () {
              const recaptcha = await load("6Lf-A7wUAAAAADrAukpQ5BSDXd3OGYhU43fDo2E2");
              this.token = await recaptcha.execute("homepage");
              // functionsの関数を呼び出すには第一引数にfunctionsを渡す必要がある。
              // ↓古い方法
              // const func = functions.httpsCallable("checkRecaptcha");
              const func = httpsCallable(
                  functions,
                  "checkRecaptcha"
              );
                await func({ token: this.token })
                    .then(async response => {
                        this.result = await response.data;
                        if (this.result.score >= 0.9)
                        {
                            this.contactForm.rc_success = true;
                            this.contactForm.check = "checked";
                            this.contactForm.check_style = "display: block"
                        }
                        this.rc_loader = null;
                    })
                    .catch(error => {
                        this.error = error;
                        this.loader = null;
                    });
            }
        }
    }
</script>

<style scoped>
    .cf-wrapper {
        width: 90vw;
        margin: 0 auto 50px;
    }

    .theme--dark.v-card {
        background-color: rgba(0, 0, 0, 0.5);
    }
    
    .recaptcha_policy {
      margin: 1em 0 0 0;
    }
</style>
