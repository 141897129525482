<template>
  <v-app>
    <Main/>
    <ContactForm/>
    <v-footer app>
      <span>&copy; 2024 Studio SION.</span>
    </v-footer>
  </v-app>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import Main from './components/Main.vue';
import ContactForm from "@/components/ContactForm.vue";

@Options({
  components: {
    ContactForm,
    Main
  },
})
export default class App extends Vue {
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
